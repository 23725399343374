import React, { FC } from 'react';
import { css, cx } from 'emotion';

export interface BrandComponentProps {
  className?: string;
  children?: JSX.Element | JSX.Element[];
}

const LoginLogo: FC<BrandComponentProps> = ({ className }) => {
  return <img className={className} src="public/img/netia_login_logo.png" alt="Netia logo" />;
};

const LoginBackground: FC<BrandComponentProps> = ({ className, children }) => {
  const background = css`
    background: url(public/img/Netia-Serwer-Statystyk-Background.png);
    background-size: cover;
  `;

  return <div className={cx(background, className)}>{children}</div>;
};

const MenuLogo: FC<BrandComponentProps> = ({ className }) => {
  return <img className={className} src="public/img/netia_logo.png" alt="Netia logo" />;
};

const LoginBoxBackground = () => {
  return css`
    background: #00283dff;
    background-size: cover;
  `;
};

const LoginStatsLogo: FC<BrandComponentProps> = ({ className }) => {
  const barStyles = css`
    width: 50%;
    margin-bottom: 10px;
  `;
  const statsStyles = css`
    width: 30%;
  `;
  return (
    <div className={className}>
      <img className={barStyles} src="public/img/netia_bar.png" alt="Netia bar" />
      <img className={statsStyles} src="public/img/netia_stats.png" alt="Netia statictics" />
    </div>
  );
};

export class Branding {
  static LoginLogo = LoginLogo;
  static LoginStatsLogo = LoginStatsLogo;
  static LoginBackground = LoginBackground;
  static MenuLogo = MenuLogo;
  static LoginBoxBackground = LoginBoxBackground;
  static AppTitle = 'Statystyki';
  static LoginTitle = 'Welcome to Grafana';
  static GetLoginSubTitle = () => {
    const slogans = [
      "Don't get in the way of the data",
      'Your single pane of glass',
      'Built better together',
      'Democratising data',
    ];
    const count = slogans.length;
    return slogans[Math.floor(Math.random() * count)];
  };
}
