import React, { FC, ReactElement, useState } from 'react';
import { selectors } from '@grafana/e2e-selectors';
import { FormModel } from './LoginCtrl';
import { Button, Form, LoginInput, Field, Icon } from '@grafana/ui';
import { css } from 'emotion';

interface Props {
  children: ReactElement;
  onSubmit: (data: FormModel) => void;
  isLoggingIn: boolean;
  passwordHint: string;
  loginHint: string;
}

const wrapperStyles = css`
  width: 100%;
  padding-bottom: 2px;
  position: relative;
`;

export const submitButton = css`
  display: block;
  margin: auto;
  padding-left: 25px;
  padding-right: 25px;
  background: #ee1264ff;
  border-radius: 10px;
`;

export const LoginForm: FC<Props> = ({ children, onSubmit, isLoggingIn, passwordHint, loginHint }) => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <div className={wrapperStyles}>
      <Form onSubmit={onSubmit} validateOn="onChange">
        {({ register, errors }) => (
          <>
            <Field invalid={!!errors.user} error={errors.user?.message}>
              <LoginInput
                prefix={<Icon name="user" />}
                autoFocus
                name="user"
                autoCapitalize="none"
                ref={register({ required: 'Username is required' })}
                placeholder={loginHint}
                aria-label={selectors.pages.Login.username}
              />
            </Field>
            <Field invalid={!!errors.password} error={errors.password?.message}>
              <LoginInput
                prefix={<Icon name="lock" />}
                suffix={
                  <Icon
                    name={showPassword ? 'eye-slash' : 'eye'}
                    onClick={() => setShowPassword(() => !showPassword)}
                  />
                }
                name="password"
                type={showPassword ? 'text' : 'password'}
                placeholder={passwordHint}
                ref={register({ required: 'Password is required' })}
                aria-label={selectors.pages.Login.password}
              />
            </Field>
            <Button aria-label={selectors.pages.Login.submit} className={submitButton} disabled={isLoggingIn}>
              {isLoggingIn ? 'Logging in...' : 'Login'}
            </Button>
            {children}
          </>
        )}
      </Form>
    </div>
  );
};
