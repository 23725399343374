import React, { FC } from 'react';
import config from 'app/core/config';

// import { Icon, IconName } from '@grafana/ui';

export interface FooterLink {
  text: string;
  icon?: string;
  url?: string;
  target?: string;
}

export let getFooterLinks = (): FooterLink[] => {
  return [
    {
      text: 'Documentation',
      icon: 'document-info',
      url: 'https://www.netia.pl/pl/srednie-i-duze-firmy/produkty/it-solutions/serwer-statystyk',
      target: '_blank',
    },
  ];
};

export let getVersionLinks = (): FooterLink[] => {
  const { buildInfo, licenseInfo } = config;
  const links: FooterLink[] = [];
  const stateInfo = licenseInfo.stateInfo ? ` (${licenseInfo.stateInfo})` : '';

  links.push({ text: `${buildInfo.edition}${stateInfo}`, url: licenseInfo.licenseUrl });

  if (buildInfo.hideVersion) {
    return links;
  }

  links.push({ text: `v${buildInfo.version} (${buildInfo.commit})` });

  if (buildInfo.hasUpdate) {
    links.push({
      text: `New version available!`,
      icon: 'download-alt',
      url: 'https://grafana.com/grafana/download?utm_source=grafana_footer',
      target: '_blank',
    });
  }

  return links;
};

export function setFooterLinksFn(fn: typeof getFooterLinks) {
  getFooterLinks = fn;
}

export function setVersionLinkFn(fn: typeof getFooterLinks) {
  getVersionLinks = fn;
}

export const Footer: FC = React.memo(() => {
  // const links = getFooterLinks().concat(getVersionLinks());
  const [checked, setChecked] = React.useState(true);

  return (
    <footer className="footer">
      <div className="text-center">
        <ul>
          {/*{links.map((link) => (*/}
          {/*  <li key={link.text}>*/}
          {/*    <a href={link.url} target={link.target} rel="noopener">*/}
          {/*      {link.icon && <Icon name={link.icon as IconName} />} {link.text}*/}
          {/*    </a>*/}
          {/*  </li>*/}
          {/*))}*/}
          {
            <div className="ex3">
              <label htmlFor="item-3">Copyright 2024 Netia S.A.</label>
              <input type="checkbox" name="one" id="item-3" checked={checked} onChange={() => setChecked(!checked)} />
              <div className="hide3">
                {/*prettier-ignore*/}
                <div className="inner3 centerLicence">
                  <div className="licenseDisplay">
                    This software is based on Grafana: Copyright 2014-2021 Grafana Labs <br />
                    Copyright 2024 Netia S.A. <br />
                    Licensed under the Apache License, Version 2.0 (the &quot;License&quot;); you may not use this file except in compliance with the License. <br />
                    You may obtain a copy of the License at http://www.apache.org/licenses/LICENSE‐2.0 <br />
                    Unless required by applicable law or agreed to in writing, software distributed under the License is distributed on an &quot;AS IS&quot; BASIS, <br />
                    WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. <br />
                    See the License for the specific language governing permissions and limitations under the License.
                  </div>
                </div>
              </div>
            </div>
          }
        </ul>
      </div>
    </footer>
  );
});

Footer.displayName = 'Footer';
